import * as React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import FileIcon from '../icons/buttons/pixfort-icon-file-content-1.svg';
import ShieldIcon from '../icons/buttons/pixfort-icon-shield-lock-1.svg';
import BookmarkIcon from '../icons/buttons/pixfort-icon-bookmark-2.svg';
import SwitchesIcon from '../icons/buttons/pixfort-icon-switches-3.svg';
import { show } from 'cookie-though';

const Privacy = ({ data, location }) => {
    

    return (
        <Layout location={location} title="Privacy Policy">
            <Seo title='Privacy Policy' />
            <div className=''>
                <main className='mx-auto mt-24 max-w-3xl px-4 sm:px-6 lg:px-8 md:mt-32'>

                    <div className='flex flex-col md:flex-row mb-4 font-semibold'>
                        <div className='py-2 md:py-4 mr-2'>
                            <Link className='justify-center md:justify-start stroke-gray-400 hover:stroke-gray-600 dark:hover:stroke-white bg-transparent text-gray-400 hover:bg-gray-100 hover:text-gray-600 dark:hover:text-white dark:hover:bg-white/10 transition ease-in-out duration-100 px-3 py-2 border border-transparent rounded-md text-sm align-middle h-full flex items-center self-auto' to='/terms'>
                                <FileIcon className='inline-block mr-2' />
                                <span className='inline-flex align-middle'> Terms of use</span>
                            </Link>
                        </div>
                        <div className='py-2 md:py-4 mr-2'>
                            <Link to='/privacy-policy' className='justify-center md:justify-start stroke-gray-600 hover:stroke-gray-900 dark:stroke-white text-gray-600 bg-gray-100  hover:text-gray-900 dark:text-white dark:bg-white/10 transition ease-in-out duration-100 px-3 py-2 border border-transparent rounded-md text-sm align-middle h-full flex items-center self-auto'>
                                <ShieldIcon className='inline-block mr-2' />
                                <span className='inline-flex align-middle'>Privacy Policy</span>
                            </Link>
                        </div>
                        <div className='py-2 md:py-4'>
                            <Link to='/legal' className='justify-center md:justify-start stroke-gray-400 hover:stroke-gray-600 dark:hover:stroke-white bg-transparent text-gray-400 hover:bg-gray-100 hover:text-gray-600 dark:hover:text-white dark:hover:bg-white/10 transition ease-in-out duration-100 px-3 py-2 border border-transparent rounded-md text-sm align-middle h-full flex items-center self-auto'>
                                <BookmarkIcon className='inline-block mr-2' />
                                <span className='inline-flex align-middle'>Legal</span>
                            </Link>
                        </div>
                    </div>                    
                    
                    <div className='text-left lg:text-left text-gray-500 dark:text-gray-400 prose dark:prose-invert max-w-none'>
                        <h1 className='text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl md:text-6xl mb-4'>
                            <span className='block xl:inline'>Privacy policy</span>
                        </h1>
                        <p className='mb-16'>Last updated on <span className='font-bold'>01/11/2022</span></p>

                        <p>This privacy policy ("Policy") describes how pixfort SARL. ("pixfort", "we", "us" or "our") collects, protects and uses the personally identifiable information ("Personal Information") you ("User", "you" or "your") may provide through the pixfort website (pixfort.com) or in the course of purchasing any pixfort products (collectively, "Website"). The Policy also describes the choices available to you regarding our use of your Personal Information and how you can access and update this information. This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage.</p>

                        <h2>General</h2>
                        
                        <p>Your privacy is important to us.</p>
                        <p>
                            It is pixfort's policy to respect your privacy regarding any information we may collect while operating our website. Accordingly, we have developed this privacy policy in order for you to understand how we collect, use, communicate, disclose and otherwise make use of
                            personal information. We have outlined our privacy policy below.
                        </p>
                        <ul>
                            <li>We will collect personal information by lawful and fair means and, where appropriate, with the knowledge or consent of the individual concerned.</li>
                            <li>Before or at the time of collecting personal information, we will identify the purposes for which information is being collected.</li>
                            <li>We will collect and use personal information solely for fulfilling those purposes specified by us and for other ancillary purposes, unless we obtain the consent of the individual concerned or as required by law.</li>
                            <li>Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.</li>
                            <li>We will protect personal information by using reasonable security safeguards against loss or theft, as well as unauthorized access, disclosure, copying, use or modification.</li>
                            <li>We will make readily available to customers information about our policies and practices relating to the management of personal information.</li>
                            <li>We will only retain personal information for as long as necessary for the fulfilment of those purposes.</li>
                        </ul>
                        <p>We are committed to conducting our business in accordance with these principles in order to ensure that the confidentiality of personal information is protected and maintained. pixfort may change this privacy policy from time to time at pixfort's sole discretion.</p>

                        <h2>Newsletters</h2>
                        <p>We might send to you casually electronic newsletters. You may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in these emails or by contacting us. However, you will continue to receive essential transactional emails.</p>

                        <h2>Cookies</h2>
                        <p>As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or "break" certain elements of the sites functionality.</p>

                        <h3>How We Use Cookies</h3> 

                        <p>We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.</p>

                        <h3>Disabling Cookies</h3> 

                        <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies.</p>

                        <p>To view and manage the website cookies:</p>
                        <div className='pb-4 mr-2 not-prose'>
                            <a onClick={show} className='inline-block cursor-pointer stroke-gray-400 hover:stroke-gray-600 dark:hover:stroke-white bg-gray-100 dark:bg-white/10 text-gray-400 hover:bg-gray-200 hover:text-gray-600 dark:hover:text-white dark:hover:bg-white/10 transition ease-in-out duration-100 px-3 py-2 border border-transparent rounded-md text-sm align-middle'>
                                <SwitchesIcon className='inline-block mr-2' />
                                <span className='inline-flex align-middle'>Manage cookies</span>
                            </a>
                        </div>

                        <h3>The Cookies We Set</h3> 

                        <p>Account related cookies: If you create an account with us then we will use cookies for the management of the signup process and general administration. These cookies will usually be deleted when you log out however in some cases they may remain afterwards to remember your site preferences when logged out.
                        Site preferences cookies: In order to provide you with a great experience on this site we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences we need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences.</p>

                        <h3>Third Party Cookies</h3>

                        <p>In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.</p>

                        <ul>
                            <li>This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content.
                            For more information on Google Analytics cookies, see the official Google Analytics page.</li>

                            <li>This site also uses Intercom.</li>
                        </ul>
                    

                        <p>Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren’t sure whether you need or not it’s usually safer to leave cookies enabled in case it does interact with one of the features you use on our site.</p>

                        <p>However if you are still looking for more information then you can contact us at support@pixfort.com</p>

                    </div>

                </main>
            </div>
            
        </Layout>
    );
};

export default Privacy;